import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {county} from 'src/app/county';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/map';
import { map } from "rxjs/operators";
import { county_contact } from 'src/county_contact';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CountyService {
  countiesUrl:string= 'http://68.188.24.10:8080/counties/search/findByOrderByCountyCode';//'https://cmapi.iltaxsale.com/';
  contactsUrl:string= '';

  constructor(private http:HttpClient) { }

  setContactsUrl(cnCode:string, offCode:string){
this.contactsUrl='http://68.188.24.10:8080/contacts/search/findBycountyCodeAndOfficeCodeAndActive?countyCode=' + cnCode +'&officeCode='+offCode+'&active=true';
//console.log(this.contactsUrl);
}
  getCounties():Observable<county[]>{   

   return this.http.get(this.countiesUrl)
   .pipe(map( response => response))
   //.pipe(map((response: Response) => response.json()))
   .pipe(map((data: any) => {
     return data._embedded.counties as county[];
   }));

  }

  getContacts():Observable<county_contact[]>{   
    

    return this.http.get(this.contactsUrl)
    .pipe(map( response => response))
    //.pipe(map((response: Response) => response.json()))
    .pipe(map((data: any) => {
      return data._embedded.contacts as county_contact[];
    }));
 
   }
  
}
