import { Component, OnInit,Input } from '@angular/core';
import { county_contact } from 'src/county_contact';
import {county} from 'src/app/county';

@Component({
  selector: 'app-county-contact',
  templateUrl: './county-contact.component.html',
  styleUrls: ['./county-contact.component.css']
})
export class CountyContactComponent implements OnInit {
  @Input() cntyContact: county_contact;
  //@Input() cnty: county;
 // @Input() props: { cntyContact: county_contact; cnty: county; };
  constructor() { }

  ngOnInit(): void {
  }

}
