<h1 class="app-top-bar">County Directory</h1>
County <select [(ngModel)]="currentCounty" (change)="resetOfficeCode()" >
    <option *ngIf="isMobile">Select a County</option>
    <option *ngFor="let cn of counties; let i=index" [ngValue]="cn" >
         {{cn.county_name}}
     </option>
  </select>
  

<app-county-info  *ngIf="currentCounty" [cnty]='currentCounty'>
</app-county-info>  
<br>
<!-- <div *ngFor="let office of offices">
    <button (click)="loadOfficeContacts({{office.code}})">
        {{office.Name}}
      </button>
      <div  *ngIf="officeCode=='office.code'">
        <app-county-contact *ngFor="let cc of contacts" [cntyContact]='cc' >      
        </app-county-contact>
      </div>
      
      <br><br>
</div> -->

<button class="fancybutton" (click)="loadTreasurer()">
    Treasurer
  </button>
    
  <div  *ngIf="officeCode=='TRE'">
    <div  *ngIf="currentCounty">
      <a [href]="'https://maps.apple.com/maps?q='+ currentCounty.treasurer_street_address +' ' + currentCounty.treasurer_street_city + ' ' + currentCounty.treasurer_street_state + ' ' + currentCounty.treasurer_street_zip " > 
        {{ currentCounty.treasurer_street_address}}
        <br>
        {{currentCounty.treasurer_street_city}}, {{currentCounty.treasurer_street_state}} {{currentCounty.treasurer_street_zip}}
        </a>
      </div>
    <app-county-contact *ngFor="let cc of contacts" [cntyContact]='cc'>      
    </app-county-contact>
  </div>
  
  <br><br>
  <button (click)="loadCountyClerk()">
    County Clerk
  </button>
  <div  *ngIf="officeCode=='CLR'">

    <div  *ngIf="currentCounty">
      <a [href]="'https://maps.apple.com/maps?q='+ currentCounty.county_clerk_street_address +' ' + currentCounty.county_clerk_street_city + ' ' + currentCounty.county_clerk_street_state + ' ' + currentCounty.county_clerk_street_zip " > 
        {{ currentCounty.county_clerk_street_address}}
        <br>
        {{currentCounty.county_clerk_street_city}}, {{currentCounty.county_clerk_street_state}} {{currentCounty.county_clerk_street_zip}}
        </a>
      </div>

    <app-county-contact *ngFor="let cc of contacts" [cntyContact]='cc'>      
    </app-county-contact>
  </div>
  <br><br>
  <button (click)="loadAssessor ()">
    Assessor
  </button>
  <div  *ngIf="officeCode=='ASE'">
    <div  *ngIf="currentCounty">
      <a [href]="'https://maps.apple.com/maps?q='+ currentCounty.assessor_street_address +' ' + currentCounty.assessor_street_city + ' ' + currentCounty.assessor_street_state + ' ' + currentCounty.assessor_street_zip " > 
        {{ currentCounty.assessor_street_address}}
        <br>
        {{currentCounty.assessor_street_city}}, {{currentCounty.assessor_street_state}} {{currentCounty.assessor_street_zip}}
        </a>
      </div>
    <app-county-contact *ngFor="let cc of contacts" [cntyContact]='cc'>      
    </app-county-contact>
  </div>
  <br><br>
  <button (click)="loadCircuitClerk()">
    Circuit Clerk
  </button>
  <div  *ngIf="officeCode=='CIR'">
    <div  *ngIf="currentCounty">
      <a [href]="'https://maps.apple.com/maps?q='+ currentCounty.circuit_clerk_street_address +' ' + currentCounty.circuit_clerk_street_city + ' ' + currentCounty.circuit_clerk_street_state + ' ' + currentCounty.circuit_clerk_street_zip " > 
        {{ currentCounty.circuit_clerk_street_address}}
        <br>
        {{currentCounty.circuit_clerk_street_city}}, {{currentCounty.circuit_clerk_street_state}} {{currentCounty.circuit_clerk_street_zip}}
        </a>
      </div>
    <app-county-contact *ngFor="let cc of contacts" [cntyContact]='cc'>      
    </app-county-contact>
  </div>
  <br><br>
  <button (click)="loadStatesAttorney()">
   States Attorney
  </button>
  <div  *ngIf="officeCode=='ATR'">
    <div  *ngIf="currentCounty">
      <a [href]="'https://maps.apple.com/maps?q='+ currentCounty.states_attorney_street_address +' ' + currentCounty.states_attorney_street_city + ' ' + currentCounty.states_attorney_street_state + ' ' + currentCounty.states_attorney_street_zip " > 
        {{ currentCounty.states_attorney_street_address}}
        <br>
        {{currentCounty.states_attorney_street_city}}, {{currentCounty.states_attorney_street_state}} {{currentCounty.states_attorney_street_zip}}
        </a>
      </div>
    <app-county-contact *ngFor="let cc of contacts" [cntyContact]='cc'>      
    </app-county-contact>
  </div>
  <br><br>
  <button (click)="loadSheriff()">
    Sheriff
   </button>
   <div  *ngIf="officeCode=='SHE'">
    <div  *ngIf="currentCounty">
      <a [href]="'https://maps.apple.com/maps?q='+ currentCounty.sheriff_street_address +' ' + currentCounty.sheriff_street_city + ' ' + currentCounty.sheriff_street_state + ' ' + currentCounty.sheriff_street_zip " > 
        {{ currentCounty.sheriff_street_address}}
        <br>
        {{currentCounty.sheriff_street_city}}, {{currentCounty.sheriff_street_state}} {{currentCounty.sheriff_street_zip}}
        </a>
      </div>
     <app-county-contact *ngFor="let cc of contacts" [cntyContact]='cc'>      
     </app-county-contact>
   </div>
   <br><br>
   <button (click)="loadCountyBoard()">
    County Board
   </button>
   <div  *ngIf="officeCode=='BRD'">
    <div  *ngIf="currentCounty">
      <a [href]="'https://maps.apple.com/maps?q='+ currentCounty.board_street_address +' ' + currentCounty.board_street_city + ' ' + currentCounty.board_street_state + ' ' + currentCounty.board_street_zip " > 
        {{ currentCounty.board_street_address}}
        <br>
        {{currentCounty.board_street_city}}, {{currentCounty.board_street_state}} {{currentCounty.board_street_zip}}
        </a>
      </div>
     <app-county-contact *ngFor="let cc of contacts" [cntyContact]='cc'>      
     </app-county-contact>
   </div>

 
  