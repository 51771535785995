<p>{{cnty.county_name}} County courthouse: 
    <br>
    <a [href]="'https://maps.apple.com/maps?q='+ cnty.county_address +' ' + cnty.county_city + ' ' + cnty.county_state + ' ' + cnty.county_zip " >
    
{{ cnty.county_address}}
<br>
{{cnty.county_city}}, {{cnty.county_state}} {{cnty.county_zip}}
</a>
<br>
Hours: {{ cnty.county_hours}} <br>
Website: <a [href]='cnty.county_website'>{{ cnty.county_website }}</a>
</p>
