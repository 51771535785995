import { Component } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { products } from './product';
import { offices } from './office';
import {county} from './county';
import { HttpClientModule } from '@angular/common/http';

import {CountyService} from 'src/app/services/county.service'
import { county_contact } from 'src/county_contact';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  products=products ;
  offices=offices;
  title = 'countydirectory';
  codes:string[];
  counties:county[];
  contacts:county_contact[];
  currentCounty:county;
 officeCode:string;
 deviceInfo = null;
 isMobile:boolean;

  constructor(private cs:CountyService, private deviceService: DeviceDetectorService){
    this.epicFunction();    
    
    cs.getCounties().subscribe(counties =>{
      this.counties=counties;
    });
    
    //this.currentCounty=this.counties[0];
  }
  epicFunction() {    
    this.deviceInfo = this.deviceService.getDeviceInfo();
     this.isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
   // console.log(this.deviceInfo);
    console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  public setFirstCounty(){
    this.currentCounty=this.counties[0];
  }

  public processClick(cnty:county){
    this.currentCounty=cnty;    
  }

  public resetOfficeCode(){
    this.officeCode="";
  }
  public loadOfficeContacts(offCode:string){
    this.officeCode=offCode;
    cs:CountyService;
    this.cs.setContactsUrl(this.currentCounty.countycode,offCode);
    this.cs.getContacts().subscribe(contacts =>{
      this.contacts=contacts;     
    });
  }
  public loadTreasurer(){
    if (this.officeCode=='TRE'){
      this.officeCode='';
    }
    else{
      this.officeCode='TRE';
      cs:CountyService;
      this.cs.setContactsUrl(this.currentCounty.countycode,'TRE');
      this.cs.getContacts().subscribe(contacts =>{
        this.contacts=contacts;   
       
      });
    }
  }
  public loadCountyClerk(){
    if (this.officeCode=='CLR'){
      this.officeCode='';
    }
    else{
    this.officeCode='CLR';
    cs:CountyService;
    this.cs.setContactsUrl(this.currentCounty.countycode,'CLR');
    this.cs.getContacts().subscribe(contacts =>{
      this.contacts=contacts;     
    });
    }
  }

  public loadAssessor(){
    if (this.officeCode=='ASE'){
      this.officeCode='';
    }
    else{
    this.officeCode='ASE';
    cs:CountyService;
    this.cs.setContactsUrl(this.currentCounty.countycode,'ASE');
    this.cs.getContacts().subscribe(contacts =>{
      this.contacts=contacts;     
    });
    }
  }
  public loadCircuitClerk(){
    if (this.officeCode=='CIR'){
      this.officeCode='';
    }
    else{
    this.officeCode='CIR';
    cs:CountyService;
    this.cs.setContactsUrl(this.currentCounty.countycode,'CIR');
    this.cs.getContacts().subscribe(contacts =>{
      this.contacts=contacts;     
    });
    }
  }

  public loadStatesAttorney(){
    if (this.officeCode=='ATR'){
      this.officeCode='';
    }
    else{
    this.officeCode='ATR';
    cs:CountyService;
    this.cs.setContactsUrl(this.currentCounty.countycode,'ATR');
    this.cs.getContacts().subscribe(contacts =>{
      this.contacts=contacts;     
    });
  }
  }

  public loadSheriff(){
    
    if (this.officeCode=='SHE'){
      this.officeCode='';
    }
    else{
    this.officeCode='SHE';
    
    cs:CountyService;
    this.cs.setContactsUrl(this.currentCounty.countycode,'SHE');
    this.cs.getContacts().subscribe(contacts =>{
      this.contacts=contacts;     
    });

  };
  }

  public loadCountyBoard(){
    if (this.officeCode=='BRD'){
      this.officeCode='';
    }
    else{
    this.officeCode='BRD';
    cs:CountyService;
    this.cs.setContactsUrl(this.currentCounty.countycode,'BRD');
    this.cs.getContacts().subscribe(contacts =>{
      this.contacts=contacts;     
    });
  }
  }

}
