import { Component, OnInit, Input } from '@angular/core';
import {county} from 'src/app/county'; 

@Component({
  selector: 'app-county-info',
  templateUrl: './county-info.component.html',
  styleUrls: ['./county-info.component.css']
})
export class CountyInfoComponent implements OnInit {
  @Input() cnty: county;

  constructor() { }

  ngOnInit(): void {
  }

}
