export const offices = [
    {
      code: 'TRE',
      Name: 'Treasurer'
    },
    {
        code: 'CLR',
        Name: 'County Clerk'
      },
      {
        code: 'ASE',
        Name: 'Assessor'
      },
      {
        code: 'CIR',
        Name: 'Circuit Clerk'
      }
  ];